<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-deals-lake-matheson-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">Deals</h1>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Roys Peak Track, Wanaka</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-xl fill-height>
          <v-layout row wrap fill-height my-0>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content" color="transparent">
                <v-layout row wrap my-0>
                  <v-flex x12 sm6 lg6 pt-0 pb-4 v-for="(deal, n) in deals" :key="n">
                    <v-card
                      tile
                      class="pa-0 elevation-0  dash-rounded"
                      :href="deal.link"
                    >
                      <v-img
                        :src="deal.thumbnail"
                        gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.35)"
                      >
                        <v-container fill-height>
                          <v-layout dark column fill-height justify-space-between>
                            <v-flex text-xs-center class="home-hero-cta deal-title white--text display-1 font-weight-medium
                            "><img :src="deal.art" :alt="deal.title" class="deal-art" :width="deal.width" :height="deal.height"/></v-flex>
                          </v-layout>
                        </v-container>
                      </v-img>
                      <v-card-text class="pa-4 d-block">
                        <span class="title d-block mb-2">{{deal.title}}</span>
                        <span class="subheading d-block grey--text">{{deal.description}}</span>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    deals : [
      {
        title: 'Motorhome Madness',
        description: "Save 10% off the daily vehicle rate!",
        link: '/deals/motorhome-madness/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/09/deals-collection-motorhomes.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/08/motorhome-madness-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Step into a dream in Western Australia',
        description: "An ancient land of wondrous, otherworldly and dreamlike adventures.",
        link: '/deals/western-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/deals-collection-western-australia.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2024/07/step-into-dream-western-australia-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Most Popular New Zealand Itineraries',
        description: "A selection of our most popular New Zealand itineraries.",
        link: '/deals/popular-new-zealand-itineraries/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deals-collection-pop-new-zealand.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/most-popular-nz-itineraries-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Most Popular Australian Itineraries',
        description: "A selection of our most popular Australian itineraries.",
        link: '/deals/popular-australian-itineraries/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deals-collection-pop-australia.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/most-popular-au-itineraries-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Great Rail Journeys of New Zealand',
        description: "Climb aboard one of New Zealand’s Great Rail Journeys.",
        link: '/deals/rail-journeys-new-zealand/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deals-collection-great-rail-journeys-nz.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/great-rail-journeys-of-new-zealand-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Great Rail Journeys of Australia',
        description: "Explore the Land Down Under by overnight sleeper train.",
        link: '/deals/rail-journeys-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deals-collection-australian-train-journeys.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/08/great-rail-journeys-of-australia-stacked.png',
        width: 240,
        height: 150
      },
      
      /*
      {
        title: 'Aussie Highlights',
        description: "Add a special stay onto your holiday down under.",
        link: '/deals/australia-highlights/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-collection-aussie-hl.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/aussie-highlights-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Independent Holidays',
        description: "Travel Australia your way.",
        link: '/deals/australia-book/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/deals-collection-au-book.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/the-australian-book-fit-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Classic New Zealand Holidays',
        description: "Experience the best of New Zealand with these popular packages.",
        link: '/deals/classic-new-zealand/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-classic-new-zealand-GBP.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/classic-new-zealand-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Iconic Rail Journeys',
        description: "Discover the romance of slow travel.",
        link: '/deals/rail-journeys/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deals-collection-kiwi-rail.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/07/iconic-rail-journeys-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Hot Touring Offers',
        description: "Visit the best of Australia & NZ with a comprehensive guided tour.",
        link: '/deals/guided-tours/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deals-collection-hot-touring.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/au-nz-hot-touring-offers-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australian and New Zealand Fly & Stay',
        description: "Escape to Australia or New Zealand with flights & a guided tour.",
        link: '/deals/fly-and-stay-australia-nz/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/deals-collection-au-nz-fly-stay.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/australian-new-zealand-fly-stay-guided-tours.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australian and New Zealand Odysseys',
        description: "Turn an adventure into an EPIC adventure.",
        link: '/deals/long-itineraries/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/deals-collection-australian-new-zealand-odysseys.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/10/australian-new-zealand-odysseys-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Signature Australian Experiences Collection',
        description: "A carefully curated selection of Australia’s most outstanding holidays.",
        link: '/deals/signature-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deals-collection-signature-australia.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/signature-australian-experiences-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Classic Australian Holidays',
        description: "A showcase of must-do Aussie holidays in iconic destinations.",
        link: '/deals/classic-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-collection-classic-aussie-holidays.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/classic-australian-holidays-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Explore more in Christchurch',
        description: "Discover Christchurch, a revitalised city in the heart of New Zealand’s South Island.",
        link: '/deals/christchurch/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/deals-collection-chc.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/explore-more-in-christchurch-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Australia’s Nature Coast Drive',
        description: "Get on the road and embark on a Queensland journey of natural beauty ",
        link: '/deals/australia-nature-coast-drive/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-australias-nature-coast-drive-2.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/australias-nature-coast-drive-deals-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Iconic Aussie Bucket List Adventures',
        description: "Make your dream a reality and discover the spectacularly diverse landscape Australia is famous for.",
        link: '/deals/australia-bucket-list/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deals-collection-australia-bucket-list-gbp.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/deal-iconic-aussie-bucket-list-2022-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'Earlybird New Zealand Coach Tours',
        description: "Book by 31 May 2022 and save on a New Zealand Coach Tour.",
        link: '/deals/new-zealand-coach-tours/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deals-collection-earlybird-coach-tours.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/earlybird-new-zealand-coach-tours-stacked.png',
        width: 240,
        height: 150
      },
      {
        title: 'All-Time Great Australian Holidays',
        description: "Discover some of the most popular Australian landmarks in this collection of must-do holiday packages.",
        link: '/deals/all-time-australia/',
        thumbnail: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deals-collection-apc-nzd.jpg',
        art: 'https://cdn.anzcro.com.au/wp-content/uploads/2022/02/all-time-great-australian-deals-2022-stacked.png',
        width: 240,
        height: 150
      },
      */
    ]
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.deal-title span{
  display:block;
  max-width: 75%;
}
</style>
